.home .main-nav-bar .nav-links > li {
  flex: 0 0 auto;
}

.home-nav-grid {
  @include flexbox(row wrap);

  margin: 0;
  border-bottom: 1rem solid $color-primary-lighter;
  padding: 0;
  list-style-type: none;

  > li {
    @include aspect-ratio(16, 10);

    flex: 1 0 100%;
    background-size: cover;
    font-size: 2em;

    @media screen and (min-width: $breakpoint-tablet) {
      flex-basis: 50%;
    }

    @media screen and (min-width: 1200px) {
      flex-basis: (100% / 3);
      font-size: 2.25em;
    }

    > a {
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
      font-weight: 300;
      letter-spacing: 0.15em;
      text-align: center;
      text-transform: lowercase;
      text-decoration: none;
      color: $color-bg;
      outline: none;

      &::before {
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        background-image: linear-gradient(to bottom, transparent, transparent 40%, rgba(0, 0, 0, 0.5));
        content: " ";
      }
    }
  }

  @mixin nav-grid-link($link-name, $color) {
    > .nav-link-#{$link-name} {
      background-image: url('../img/bg-#{$link-name}.min.jpg');

      > a {
        background-color: transparentize($color, 0.5);
        transition: background-color 0.5s ease;

        &:hover, &:focus { background-color: transparent; }
      }
    }
  }

  @include nav-grid-link(coaching, $color-primary-light);
  @include nav-grid-link(courses, $color-primary);
  @include nav-grid-link(mediations, $color-primary-dark);
  @include nav-grid-link(about, $color-secondary-darker);
  @include nav-grid-link(projects, $color-secondary-dark);
  @include nav-grid-link(articles, $color-secondary);

  .nav-grid-text {
    position: absolute;
    bottom: 0;
    left: 0; right: 0;
    padding-bottom: 0.5em;
    overflow: hidden;
  }
}

.facebook-drawer-container {
  position: relative;
}

.facebook-drawer {
  position: absolute;
  top: 1rem;
  right: -300px;
  width: 300px;
  height: 400px;
  border: 2px solid $color-primary-dark;
  border-right-width: 0;
  transition: right 0.5s ease;

  &:hover {
    right: 0;
  }
}

.facebook-drawer-handle {
  position: absolute;
  top: 1rem;
  left: -50px;
  width: 50px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-color: $color-primary-dark;
  line-height: 50px;
  font-size: 2em;
  text-align: center;
  color: $color-bg;
}

.page-section.home {
  padding: 1rem 0;

  .page-title {
    color: $color-primary-lighter;

    &.opinions {
      color: $color-secondary;
    }

    .highlight {
      color: $color-secondary;
    }
  }

  &.inverted {
    background-color: $color-primary-lighter;
    color: $color-bg;

    .page-title {
      color: $color-bg;
    }
  }
}

.newsletter-signup {
  text-align: center;

  input {
    min-width: 15em;
    margin-right: 1rem;
    margin-bottom: 0.5em;
    border: 1px solid $color-ui-border;
    border-radius: 0.25em;
    padding: 0 0.25em;
    background-color: transparent;
    line-height: 2em;
  }
}

.newsletter-consent {
  font-size: 0.8rem;
  opacity: 0.5;

  a {
    color: inherit;
  }
}
