.coaching {
  &.header-background {
    border-color: $color-primary-lighter;
    background-image: url('../img/bg-coaching.jpg');
    background-position: center 25%;
  }

  .page-title {
    color: $color-primary-lighter;
  }

  .page-subheader {
    color: $color-secondary-lighter;
  }
}

.coaching-examples {
  padding-left: 1em;
  font-style: italic;
}
