.main-nav-bar {
  background-color: $color-primary-darker;
  line-height: $navbar-height;
  text-align: center;
  color: $color-bg;

  @media screen and (min-width: $breakpoint-tablet) {
    height: $navbar-height;
  }

  a {
    display: inline-block;
    height: $navbar-height;
    text-decoration: none;
    color: $color-bg;
  }

  img {
    max-height: $navbar-height;
  }

  .nav-links {
    @include flexbox(column nowrap, center);

    margin: 0;
    padding: 0;
    list-style-type: none;

    @media screen and (min-width: $breakpoint-tablet) {
      flex-flow: row nowrap;
      height: $navbar-height;
      overflow: hidden;
    }

    > li {
      flex: 1 1 0;
      text-transform: lowercase;
      font-size: 1.25em;

      > a {
        width: 100%;
      }
    }

    @media screen and (min-width: $breakpoint-tablet) {
      > .nav-link-coaching,
      > .nav-link-courses,
      > .nav-link-mediations {
        order: -1;
      }
    }

    @mixin nav-link-hover($link-name, $color) {
      > .nav-link-#{$link-name} > a {
        &:hover, &:focus { background-color: $color; }
      }
    }

    @include nav-link-hover(coaching, $color-primary-light);
    @include nav-link-hover(courses, $color-primary);
    @include nav-link-hover(mediations, $color-primary-dark);
    @include nav-link-hover(about, $color-secondary-darker);
    @include nav-link-hover(projects, $color-secondary-dark);
    @include nav-link-hover(articles, $color-secondary);
  }
}

.header-background {
  @include aspect-ratio(16, 5);

  border-bottom: 1rem solid $color-ui-border;
  background-size: cover;
}

.admin-nav {
  flex: 0 0 300px;
  background-color: $color-primary-darker;
  color: $color-primary-lighter;

  ul {
    margin: 1em 0;
    padding: 0;
    list-style-type: none;

    > li {
      display: block;
    }
  }

  a {
    display: block;
    padding: 0.25em 0.5em;
    text-decoration: none;
    color: $color-primary-lighter;
    transition: background-color 0.25s ease;

    &:hover, &:focus { background-color: $color-primary; }

    .fa {
      display: inline-block;
      width: 2em;
      text-align: center;
    }
  }
}
