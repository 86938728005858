.about {
  &.header-background {
    border-color: $color-secondary;
    background-image: url('../img/bg-about.jpg');
    background-position: center 10%;
  }

  .page-title {
    color: $color-secondary;

    .highlight {
      color: $color-primary-lighter;
    }
  }
}
