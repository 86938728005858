.projects {
  &.header-background {
    border-color: $color-secondary;
    background-image: url('../img/bg-projects.jpg');
    background-position: center 25%;
  }

  .page-title {
    color: $color-secondary;
  }
}

.project-name {
  color: $color-primary-lighter;
}
