*, *::before, *::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  overflow-x: hidden;
}

body {
  @include flexbox(column nowrap);

  min-height: 100%;
  background-color: $color-bg;
  color: $color-text;

  &.admin {
    @include flexbox(row nowrap);
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0.25em 0;
  font-weight: 300;
}

h1 { font-size: (7em / 3); }
h2 { font-size: (5em / 3); }
h3 { font-size: (4em / 3); }
h4 { font-size: 1em; }
h5 { font-size: 0.8em; }
h6 { font-size: (2em / 3); }

p {
  margin: 0.5em 0;
}

a {
  color: $color-secondary-darker;
  transition: color 0.25s ease;

  img {
    vertical-align: bottom;
  }
}

.container {
  width: 100%;
  max-width: $layout-desktop-width;
  margin: 0 auto;
  padding: 0 1rem;
}

// TODO: move to separate file
.slick-dots {
  font-size: 1rem;
}

#modal-overlay {
  @include flexbox(column nowrap, center, center);

  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
