.clndr-controls {
  @include flexbox(row nowrap, space-between, baseline);
}

.clndr-dotw,
.clndr-days {
  padding-top: 0.5em;

  > div {
    flex: 1 1 (100% / 7);
    text-align: center;
  }
}

.clndr-dotw {
  @include flexbox(row nowrap, space-around, baseline);
}

.clndr-days {
  @include flexbox(row wrap, space-around, baseline);
}
