.panel {
  @include flexbox(column nowrap);
  max-width: 100%;
  margin: 1rem auto;
  border: 1px solid $color-ui-border;
  border-radius: 0.25em;
  box-shadow: 0 2px 3px -2px transparentize($color-text, 0.5);
  font-size: 1rem;

  @media screen and (min-width: $breakpoint-tablet){
    max-width: ($layout-desktop-width / 3);

    &.wide {
      width: 100%;
      max-width: ($layout-desktop-width * 2 / 3);
    }
  }

  > header {
    border-bottom: 1px solid $color-ui-border;
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
    padding: 0.75em;
    background-color: $color-ui-bg;

    > h2,
    > h3 {
      margin: 0;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  > .panel-image {
    @include aspect-ratio(3, 1);

    display: block;
    width: 100%;
    background-image: url('../img/bg-courses.min.jpg');
    background-position: center center;
    background-size: cover;

    + .panel-body {
      border-top: 1px solid $color-ui-border;
    }
  }

  > .panel-body {
    flex: 1 1 auto;
    padding: 0.75em;
    background-color: $color-bg;

    &:last-child {
      padding-bottom: 0.25em;
      margin-bottom: 0.5em;
    }
  }

  > footer {
    border-top: 1px solid $color-ui-border;
    border-bottom-left-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
    padding: 0.75em;
    background-color: $color-ui-bg;
  }
}
