.admin-table {
  width: 100%;
  background-color: $color-bg;
  font-size: 1rem;
  white-space: nowrap;

  th, td {
    padding: 0.25em;
    text-overflow: ellipsis;
    overflow: hidden;

    &:last-child {
      width: 1%;
    }
  }

  th {
    text-align: left;
  }

  .button {
    margin-right: 0.5em;

    &:last-child {
      margin-right: 0;
    }
  }
}
