.form-field {
  @include flexbox(row wrap, space-between, baseline);

  margin-bottom: 0.5em;

  &:last-child { margin-bottom: 0; }

  > label {
    display: inline-block;

    &:first-child {
      min-width: (100% / 3);
      padding-right: 0.25em;
    }
  }

  > .form-field-input {
    flex-grow: 1;

    > input {
      &[type="text"],
      &[type="email"],
      &[type="password"],
      &[type="url"],
      &[type="search"],
      &[type="tel"],
      &[type="number"] {
        width: 100%;
        border: 1px solid $color-ui-border;
        border-radius: 0.25em;
        padding: 0 0.25em;
        background-color: transparent;
        line-height: 2em;

        &:not(:last-child) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        + .validation-message {
          margin-top: -1px;
          border-bottom-left-radius: 0.25em;
          border-bottom-right-radius: 0.25em;
        }
      }
    }

    > textarea {
      width: 100%;
      border: 1px solid $color-ui-border;
      border-radius: 0.25em;
      padding: 0.25em;
      background-color: transparent;

      &:not(:last-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      + .validation-message {
        margin-top: -1px;
        border-bottom-left-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
      }
    }

    > .validation-message {
      border: 1px solid $color-failure-border;
      padding: 0.25em;
      background-color: $color-failure-bg;
      text-align: right;
      color: $color-failure;
    }
  }

  &.form-toggle {
    > label {
      align-self: center;
    }

    > .form-field-input {
      > input {
        display: none;

        + label {
          font-size: 1.5em;

          .fa-toggle-off { color: $color-ui-border; }
          .fa-toggle-on { display: none; color: $color-success; }
        }

        &:checked + label {
          .fa-toggle-off { display: none; }
          .fa-toggle-on { display: inline-block; }
        }
      }
    }
  }
}

.privacy-notice {
  margin-top: 0.75rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  opacity: 0.5;
}

form.panel > footer {
  @include flexbox(row nowrap, space-between, baseline);
}
