.articles {
  &.header-background {
    border-color: $color-secondary;
    background-image: url('../img/bg-articles.jpg');
    background-position: center 40%;
  }

  .page-title {
    color: $color-secondary;
  }
}

.article-list {
  @include flexbox(row wrap);

  > .list-item {
    flex: 0 1 100%;
    padding-bottom: 1rem;
    overflow-x: hidden;

    @media screen and (min-width: $breakpoint-tablet) {
      flex-basis: 50%;

      &:nth-child(odd) {
        padding-right: 0.5rem;
      }

      &:nth-child(even) {
        padding-left: 0.5rem;
      }
    }

    > .panel {
      max-width: none;
      height: 100%;
      margin: 0;

      > footer {
        @include flexbox(row nowrap, space-between, center);
      }
    }
  }
}

.article-content img {
  width: 100%;
}
