.quote-carousel {
  width: 100%;

  @media screen and (min-width: $breakpoint-tablet) {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

.quote-carousel-slide {
  position: relative;
  margin: 0;

  .fa {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 5rem;
    color: $color-secondary-lighter;
    opacity: 0.25;
    z-index: -1;
  }
}

blockquote {
  margin: 1rem;
  font-style: italic;

  + figcaption {
    padding-right: 2rem;
    text-align: right;
  }
}
