.mediations {
  &.header-background {
    border-color: $color-primary-lighter;
    background-image: url('../img/bg-mediations.jpg');
    background-position: center 65%;
  }

  .page-title {
    color: $color-primary-lighter;
  }

  .page-subheader {
    color: $color-secondary-lighter;
  }
}
