.courses {
  &.header-background {
    border-color: $color-primary-lighter;
    background-image: url('../img/bg-courses.jpg');
    background-position: center 40%;
  }

  .page-title {
    color: $color-primary-lighter;
  }
}

.course-categories {
  padding-bottom: 1rem;
  text-align: center;
}

.course-category {
  display: inline-block;
  width: 40%;
  padding: 1rem;

  @media screen and (min-width: $breakpoint-tablet) {
    width: 20%;
  }

  a {
    display: block;
    text-decoration: none;
    font-size: 1.5rem;
    color: $color-secondary;

    &:hover, &:focus {
      color: $color-secondary-lighter;
    }
  }

  .fa {
    font-size: 3rem;
  }
}

.course-list {
  @include flexbox(row wrap);

  > .list-item {
    flex: 0 1 100%;
    padding-bottom: 1rem;
    overflow-x: hidden;

    @media screen and (min-width: $breakpoint-tablet) {
      flex-basis: 50%;

      &:nth-child(odd) {
        padding-right: 0.5rem;
      }

      &:nth-child(even) {
        padding-left: 0.5rem;
      }
    }

    > .panel {
      max-width: none;
      height: 100%;
      margin: 0;

      > footer {
        @include flexbox(row nowrap, space-between, center);
      }
    }

    .course-price {
      font-size: 1.5em;
      color: $color-primary-lighter;
    }

    .course-location {
      text-align: right;
    }
  }
}

.course-image {
  @include aspect-ratio(3, 1);

  width: 100%;
  margin: 1rem 0;
  background-position: center center;
  background-size: cover;
}

.course-editions {
  color: $color-primary-lighter;
}

.edition-details {
  padding-left: 1em;
}
