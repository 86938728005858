$color-primary-darker:    #031745;
$color-primary-dark:      #072262;
$color-primary:           #0b2d7a;
$color-primary-light:     #133a94;
$color-primary-lighter:   #3154a4;

$color-secondary-darker:  #53002f;
$color-secondary-dark:    #760043;
$color-secondary:         #920053;
$color-secondary-light:   #b10566;
$color-secondary-lighter: #c42b82;

$color-tertiary-darker:   #406000;
$color-tertiary-dark:     #5b8900;
$color-tertiary:          #71aa00;
$color-tertiary-light:    #8ccf05;
$color-tertiary-lighter:  #a9e432;

$color-text:              #222;
$color-bg:                #f4f4f4;

$color-ui-border:         #ccc;
$color-ui-bg:             #eee;

$color-success:           $color-tertiary-dark;
$color-success-border:    change-color($color-success, $lightness: lightness($color-ui-border));
$color-success-bg:        change-color($color-success, $lightness: lightness($color-ui-bg));

$color-failure:           #b60000;
$color-failure-border:    change-color($color-failure, $lightness: lightness($color-ui-border));
$color-failure-bg:        change-color($color-failure, $lightness: lightness($color-ui-bg));

$breakpoint-tablet:       640px;
$breakpoint-desktop:      960px;

$layout-desktop-width:    1200px;

$navbar-height:           3rem;
