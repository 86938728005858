.page-footer {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: $color-primary-darker;
  color: $color-bg;

  a {
    color: $color-primary-lighter;
  }

  > .footer-content {
    @include flexbox(column nowrap);

    > .footer-links {
      text-align: center;

      img {
        max-width: 100%;
      }

      .footer-logo {
        max-width: 80%;
        max-height: 8rem;
      }
    }

    > .footer-contact-info {
      text-align: center;
    }

    .footer-social-links {
      font-size: 3rem;

      a {
        margin: 0 0.25em;
      }
    }

    @media screen and (min-width: $breakpoint-tablet) {
      @include flexbox(row nowrap, space-around);

      > .footer-links {
        width: auto;
      }

      > .footer-contact-info {
        order: -1;
        width: auto;
        text-align: left;

        p {
          margin-top: 0.5em;
        }
      }
    }
  }

  > .copyright {
    padding-top: 1rem;
    text-align: center;
    font-size: 0.8rem;
    color: $color-primary-light;

    a {
      text-decoration: none;
    }
  }
}
