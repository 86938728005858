.page-section {
  flex: 1 0 auto;

  &:last-of-type {
    padding-bottom: 2em;
  }
}

.page-content {
  @include flexbox(column nowrap);

  padding: 0.5rem 0.5rem 1rem;

  @media screen and (min-width: $breakpoint-desktop) {
    @include flexbox(row nowrap, center, flex-start);
  }

  > .page-content-main {
    flex: 1 1 auto;
    padding: 0 0.5rem;
    overflow-x: hidden;
  }

  > .page-content-sidebar {
    @include flexbox(row wrap, space-between);

    margin-top: 0.75rem;
    border-top: 1px solid $color-ui-border;
    padding-top: 0.5rem;

    @media screen and (min-width: $breakpoint-desktop) {
      flex: 0 0 ($layout-desktop-width / 4);
      margin-left: 2rem;
      border-top: 0 none;
      border-left: 1px solid $color-ui-border;
      padding-top: 0;
      overflow-x: hidden;
    }

    > .sidebar-section {
      flex: 0 1 100%;
      padding: 0 0.5rem;
      overflow-x: hidden;

      @media screen and (min-width: $breakpoint-tablet) {
        flex-basis: 50%;
      }

      @media screen and (min-width: $breakpoint-desktop) {
        flex-basis: 100%;
      }

      > .sidebar-section-title {
        margin-top: 0;
        margin-bottom: 0.5em;
      }

      img {
        max-width: 100%;
      }
    }
  }
}

.sidebar-upcoming {
  padding-left: 1em;
  list-style-type: none;

  > li {
    margin-bottom: 1em;
  }

  a {
    text-decoration: none;

    &:hover, &:focus {
      color: $color-secondary-lighter;
    }
  }
}

.sidebar-calendar {
  margin-bottom: 1em;
  font-size: 1rem;
}

.list-item {
  h2 > a {
    text-decoration: none;

    &:hover, &:focus {
      color: $color-secondary-lighter;
    }
  }
}

.media-links {
  padding: 0;
  text-align: center;
  list-style-type: none;

  > li {
    display: inline-block;
    margin-bottom: 1em;
    padding: 0 1em;

    img {
      height: 3rem;
      vertical-align: bottom;
    }
  }
}

.youtube-embed {
  @include aspect-ratio(16, 9);

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0 none;
  }
}

.terms-list {
  max-width: 40rem;
  line-height: 1.4;

  ol {
    list-style-type: lower-latin;
  }

  li {
    margin-top: 0.5rem;
  }
}

.admin-main {
  flex: 1 1 auto;
  background-color: $color-bg;

  h1 {
    margin: 0 0 0.5em;
    border-bottom: 1px solid $color-ui-border;
    padding: 1.5rem;
    background-color: $color-ui-bg;
  }

  > div {
    padding: 0 1.5rem 1rem;
  }

  .preview {
    max-width: ($layout-desktop-width * 2 / 3);
    padding-left: 2rem;
  }
}
