@mixin flexbox($flow: row nowrap, $justify-content: flex-start, $align-items: stretch, $align-content: stretch) {
  display: flex;
  flex-flow: $flow;
  justify-content: $justify-content;
  align-items: $align-items;
  align-content: $align-content;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    width: 100%;
    padding-top: (100% * $height / $width);
    content: " ";
  }
}
