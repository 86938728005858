.button {
  display: inline-block;
  border: 1px solid $color-ui-border;
  border-radius: 100em;
  padding: 0 0.75em;
  background-color: transparent;
  background-image: none;
  line-height: 2em;
  text-align: center;
  text-decoration: none;
  color: inherit;
  transition: 0.25s ease;
  transition-property: border-color, color;

  &.animated {
    &:hover,
    &:focus {
      border-color: $color-primary-lighter;
      color: $color-primary-lighter;
    }
  }

  &.cta {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .fa {
    margin: 0 0.25em;
  }
}
